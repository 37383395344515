import Constant from "../constants/schemaConstants";

export const getSchemaData = (
  sn_id: string,
  sne_id: string,
  value: string
) => ({
  type: Constant.STORE_SCHEMA,
  sn_id,
  sne_id,
  value,
});

export const getCalculationData = (payload: any) => ({
  type: Constant.STORE_CALCULATION,
  payload,
});

export const getSizingResults = (payload: any) => ({
  type: Constant.STORE_SIZING_RESULTS,
  payload,
});

export const getLeftAnchorData = (payload: any) => ({
  type: Constant.STORE_LEFT_ANCHOR,
  payload,
});

export const getRightAnchorData = (payload: any) => ({
  type: Constant.STORE_RIGHT_ANCHOR,
  payload,
});

export const updateLeftAnchorData = (payload: any) => ({
  type: Constant.STORE_UPDATE_LEFT_ANCHOR,
  payload,
});

export const updateLeftAnchorkeyData = (payload: any) => ({
  type: Constant.STORE_UPDATE_LEFT_KEY_ANCHOR,
  payload,
});

export const updateRightAnchorData = (payload: any) => ({
  type: Constant.STORE_UPDATE_RIGHT_ANCHOR,
  payload,
});

export const updateRightAnchorKeyData = (payload: any) => ({
  type: Constant.STORE_UPDATE_RIGHT_KEY_ANCHOR,
  payload,
});
export const clearRightAnchorValues = (payload:any) => ({
  type: Constant.CLEAR_RIGHT_ANCHOR_VALUES,
  payload
});
export const clearRightAnchorData = (payload: any) => ({
  type: Constant.CLEAR_RIGHT_ANCHOR,
  payload,
});

export const getProductionListData = (payload: any) =>({
  type: Constant.STORE_PRODUCTION_LIST,
  payload,
})

export const getLanguageListData = (payload: any) => ({
  type: Constant.STORE_LANGUAGE_LIST,
  payload
});

export const updateCurrentId = (payload: any) => ({
  type: Constant.UPDATE_CURRENT_NEID,
  payload,
});


export const getActiveSession = (payload: any) => {
  return ({
    type: Constant.STORE_ACTIVE_SESSION,
    payload,
  });
}

export const deleteActiveSession = (payload: any) => {
  return ({
    type: Constant.DELETE_ACTIVE_SESSION,
    payload,
  });
}

