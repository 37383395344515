import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension";
import reducers from "./rootReducer";
import saga from "./rootSaga";
import { createLogger } from "redux-logger";
import { getEnv } from "../utils/Helper";


const middleWare:any = [];

const sagaMiddleware:any = createSagaMiddleware();

middleWare.push(sagaMiddleware);

const logger:any = createLogger({
  predicate: () => getEnv("NODE_ENV") === "development",
});

middleWare.push(logger);

const composeEnhancers = composeWithDevTools({
  // Specify name here, actionsBlacklist, actionsCreators and other options if needed
});

const store = createStore(
  reducers,
  composeEnhancers(compose(applyMiddleware(...middleWare)))
);

sagaMiddleware.run(saga);

export type RootState = ReturnType<typeof store.getState>;

export default store;
