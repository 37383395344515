import Loader from './Loader';
import { connect } from 'react-redux';

const mapStateToprops = (state: any) => {
    return {
        loading: state.schemaData.loading
    }
};

export default connect(mapStateToprops)(Loader);

