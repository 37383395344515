import React, { useEffect, useState } from 'react';
import { Switch, Route, BrowserRouter, useHistory } from 'react-router-dom';
import { SecureRoute, Security, LoginCallback, useOktaAuth } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import Home from './containers/Home';
import Header from './components/Header';
import Loader from './components/Loader';
import config from './utils/okta.config';
import { getEnv } from "./utils/Helper";
import store from './store/Store';
import { Provider } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getActiveSession } from './store/actions/schemaActions' 



const oktaAuth = new OktaAuth(config.oidc);

export default function App() {
    
    const { t, i18n } = useTranslation();

    const history = useHistory();

    const [active, setActive] = useState(false)

    let showHeader = !(new URLSearchParams(window.location.search.substring(1)).get('bu_code'));
    
    const restoreOriginalUri = async (oktaAuth: any, originalUri: any) => {
        history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
    };

    useEffect(()=>{
       store.dispatch(getActiveSession(null))
    },[])

    return (
        <Provider store={store}>
            <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
                <Header showHeader={showHeader} />
                <Loader />
                <Switch>
                    <div>
                        <Route path={`${getEnv("PUBLIC_URL")}/`} exact={true} component={Home} />
                        <Route path={`${getEnv("PUBLIC_URL")}/level`} component={Home} />
                        <Route path={`${getEnv("PUBLIC_URL")}/:locale/catalog/level`} component={Home} />
                        <Route path={`${getEnv("PUBLIC_URL")}/login/callback`} component={LoginCallback} />
                    </div>
                </Switch>
            </Security>
        </Provider>
    )
}

