import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import ReactDOM from 'react-dom';
import './styles/all.css';
import './styles/custom.css';
import '@emerson/dynamic-ui-dev/dist/emerson-ui.css';
import reportWebVitals from './reportWebVitals';
import './i18n';
import { BrowserRouter as Router } from 'react-router-dom';


ReactDOM.render(
  <React.StrictMode>
    <React.Suspense fallback="Loading...">
      <Router>
        <App />
      </Router>
    </React.Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
