export const isElementsExists = (arr: any, name: any) => {
  return arr.some(function (el: any) {
    return el.label === name;
  });
};

export const upsert = (array: any, item: any, key: string) => {
  const i = array.findIndex((_item: any) => _item[key] === item[key]);
  if (i > -1) array[i] = item;
  else array.push(item);
};

export const getEnv = (name: string) => {
  // @ts-ignore
  const RUNTIME_ENV = window?._env_?.[name]
    ? // @ts-ignore
      window._env_[name]
    : // @ts-ignore
      process.env[name];

  return RUNTIME_ENV;
};

export const isJson = (input: string) => {
  try {
    JSON.parse(input);
  } catch (e) {
    return false;
  }
  return true;
};

export const getTranslateCode = (std_code: string, arr: any[] ) => {
  try {
    const transObject = arr.find((item)=> item.std_code==std_code);
    return transObject.trans_code ? transObject.trans_code : "en_US"
  } catch (e) {
    return "en_US"
  }
};

export const lowerToUpper = (language: string) => {
  try {
    return language.replace(`_${language.split('_')[1]}`, `_${language.split('_')[1].toUpperCase()}`)
  } catch (e) {
    return "en_US"
  }
};




