import { all } from "redux-saga/effects";

import {
  storeSchema,
  storeCalculation,
  storeSizingResults,
  storeLeftAnchor,
  storeRightAnchor,
  getProductionList,
  getLanguageList,
  storeActiveSession,
  deleteActiveSession
} from "./saga/schemaSaga";

function* rootSaga() {
  yield all([
    storeSchema(),
    storeCalculation(),
    storeSizingResults(),
    storeLeftAnchor(),
    storeRightAnchor(),
    getProductionList(),
    getLanguageList(),
    storeActiveSession(),
    deleteActiveSession()
  ]);
}

export default rootSaga;
