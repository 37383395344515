import axios from "axios";

import { getEnv } from "../../utils/Helper"
const base_url = getEnv("REACT_APP_API_BASE_URL");
const API_token = getEnv("REACT_APP_API_TOKEN");

export async function technologyComparision(compare_object){
  try {
    const response = await axios({
      method: "GET",
      url: `${base_url}/assembler/compare_api?bu_code=FLOW_PA&compare_factor=Technology Comparison&compare_object=${compare_object.toString()}`,
      headers: { "Content-Type": "application/json", Authorization: API_token },
    });
    var tech = [];
    if (response.status == 200) {
      tech = response.data;
    }
    return tech;

  } catch (error) {
    console.log("Exception from technologyComparision : " + error);
    return [];
  }
}

export async function fluidPropertiesCalculation(process_variables) {
  try {
    const default_data = {
      flow_rate_min: "",
      flow_rate_norm: "",
      flow_rate_max: "",
      flow_rate_full: "",
      line_pressure_min: "",
      line_pressure_norm: "",
      line_pressure_max: "",
      temperature_min: "",
      temperature_norm: "",
      temperature_max: "",
      ambient_temp_min: "",
      ambient_temp_norm: "",
      ambient_temp_max: "",
      temperature_fsd: "",
      line_pressure_fsd: "",
      density_variation_flag: false,
      calculation: "liquid",
      fluid_name: "water",
      coolprops_flag: true,
      flow_rate_units: "USGPH",
      line_pressure_units: "PSIG",
      temperature_units: "F",
      ambient_temp_units: "F",
      sonic_velocity: "",
      sonic_velocity_units: "M/SEC",
      density_units: "LB/GAL(US)",
      viscosity_units: "CP",
      vapor_pressure_units: "PSIA",
      language: "US",
      viscosity_min_custom: "",
      viscosity_norm_custom: "",
      viscosity_max_custom: "",
      ref_temperature: "",
      ref_temperature_units: "C",
      ref_pressure: 1.01325348872,
      ref_pressure_units: "BAR-A",
      ref_density: "",
      ref_density_units: "KG/M3",
      exclude_model: "",
    };
    const data = { ...default_data, ...process_variables };
    const response = await axios({
      method: "POST",
      url: `https://flowproductadvisorservices-dev.azurewebsites.net/mmipa/calculations/`,
      headers: { "Content-Type": "application/json" },
      data: JSON.stringify(data),
    });


    var tech = [];
    if (response.status == 200) {
      tech = response.data;
    }
    return tech;
  } catch (error) {
    console.log("Exception from technologyComparision : " + error);
    return [];
  }
}