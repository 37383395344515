const STORE_SCHEMA_SUCCESS = "STORE_SCHEMA_SUCCESS";
const STORE_SCHEMA_ERROR = "STORE_SCHEMA_ERROR";
const STORE_SCHEMA = "STORE_SCHEMA";

const STORE_CALCULATION_SUCCESS = "STORE_CALCULATION_SUCCESS";
const STORE_CALCULATION_ERROR = "STORE_CALCULATION_ERROR";
const STORE_CALCULATION = "STORE_CALCULATION";

const STORE_SIZING_RESULTS_SUCCESS = "STORE_SIZING_RESULTS_SUCCESS";
const STORE_SIZING_RESULTS_ERROR = "STORE_SIZING_RESULTS_ERROR";
const STORE_SIZING_RESULTS = "STORE_SIZING_RESULTS";

const STORE_TOP_ANCHOR = "STORE_TOP_ANCHOR";

const STORE_LEFT_ANCHOR_SUCCESS = "STORE_LEFT_ANCHOR_SUCCESS";
const STORE_LEFT_ANCHOR_ERROR = "STORE_LEFT_ANCHOR_ERROR";
const STORE_LEFT_ANCHOR = "STORE_LEFT_ANCHOR";

const STORE_RIGHT_ANCHOR_SUCCESS = "STORE_RIGHT_ANCHOR_SUCCESS";
const STORE_RIGHT_ANCHOR_ERROR = "STORE_RIGHT_ANCHOR_ERROR";
const STORE_RIGHT_ANCHOR = "STORE_RIGHT_ANCHOR";

const STORE_UPDATE_RIGHT_ANCHOR = "STORE_UPDATE_RIGHT_ANCHOR";
const STORE_UPDATE_LEFT_ANCHOR = "STORE_UPDATE_LEFT_ANCHOR";
const STORE_UPDATE_RIGHT_KEY_ANCHOR = "STORE_UPDATE_RIGHT_KEY_ANCHOR";
const STORE_UPDATE_LEFT_KEY_ANCHOR = "STORE_UPDATE_LEFT_KEY_ANCHOR";

const CLEAR_RIGHT_ANCHOR = "CLEAR_RIGHT_ANCHOR";

const STORE_PRODUCTION_LIST = "STORE_PRODUCTION_LIST";
const STORE_PRODUCTION_LIST_SUCCESS = "STORE_PRODUCTION_LIST_SUCCESS";
const STORE_PRODUCTION_LIST_ERROR = "STORE_PRODUCTION_LIST_ERROR";

const STORE_LANGUAGE_LIST = "STORE_LANGUAGE_LIST";
const STORE_LANGUAGE_LIST_SUCCESS = "STORE_LANGUAGE_LIST_SUCCESS";
const STORE_LANGUAGE_LIST_ERROR = "STORE_LANGUAGE_LIST_ERROR";
const UPDATE_CURRENT_NEID = "UPDATE_CURRENT_NEID";

const STORE_ACTIVE_SESSION = "STORE_ACTIVE_SESSION";
const STORE_ACTIVE_SESSION_SUCCESS = "STORE_ACTIVE_SESSION_SUCCESS";
const STORE_ACTIVE_SESSION_ERROR = "STORE_ACTIVE_SESSION_ERROR";

const DELETE_ACTIVE_SESSION = "DELETE_ACTIVE_SESSION";
const DELETE_ACTIVE_SESSION_SUCCESS = "DELETE_ACTIVE_SESSION_SUCCESS";
const DELETE_ACTIVE_SESSION_ERROR = "DELETE_ACTIVE_SESSION_ERROR";

const STORE_GET_ELEMENT_DATA = "STORE_GET_ELEMENT_DATA";

const CLEAR_RIGHT_ANCHOR_VALUES = "CLEAR_RIGHT_ANCHOR_VALUES";

const Constant = {
  STORE_SCHEMA_SUCCESS,
  STORE_SCHEMA_ERROR,
  STORE_SCHEMA,

  STORE_CALCULATION_SUCCESS,
  STORE_CALCULATION_ERROR,
  STORE_CALCULATION,

  STORE_SIZING_RESULTS_SUCCESS,
  STORE_SIZING_RESULTS_ERROR,
  STORE_SIZING_RESULTS,

  STORE_TOP_ANCHOR,

  STORE_LEFT_ANCHOR_SUCCESS,
  STORE_LEFT_ANCHOR_ERROR,
  STORE_LEFT_ANCHOR,

  STORE_RIGHT_ANCHOR_SUCCESS,
  STORE_RIGHT_ANCHOR_ERROR,
  STORE_RIGHT_ANCHOR,

  STORE_UPDATE_LEFT_ANCHOR,
  STORE_UPDATE_RIGHT_ANCHOR,
  STORE_UPDATE_RIGHT_KEY_ANCHOR,
  STORE_UPDATE_LEFT_KEY_ANCHOR,

  CLEAR_RIGHT_ANCHOR,

  STORE_PRODUCTION_LIST,
  STORE_PRODUCTION_LIST_SUCCESS,
  STORE_PRODUCTION_LIST_ERROR,

  STORE_LANGUAGE_LIST,
  STORE_LANGUAGE_LIST_SUCCESS,
  STORE_LANGUAGE_LIST_ERROR,

  UPDATE_CURRENT_NEID,

  STORE_ACTIVE_SESSION,
  STORE_ACTIVE_SESSION_SUCCESS,
  STORE_ACTIVE_SESSION_ERROR,

  DELETE_ACTIVE_SESSION,
  DELETE_ACTIVE_SESSION_SUCCESS,
  DELETE_ACTIVE_SESSION_ERROR,

  STORE_GET_ELEMENT_DATA,

  CLEAR_RIGHT_ANCHOR_VALUES,
};

export default Constant;
