import { getEnv } from "../utils/Helper";

const CLIENT_ID = getEnv("REACT_APP_CLIENT_ID") || "{clientId}";
const ISSUER = getEnv("REACT_APP_ISSUER") || "https://{yourOktaDomain}.com/oauth2/default";
const OKTA_TESTING_DISABLEHTTPSCHECK = getEnv("OKTA_TESTING_DISABLEHTTPSCHECK") || false;
const REDIRECT_URI = `${window.location.origin}${getEnv("PUBLIC_URL")}/login/callback`;

const config = {
  oidc: {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri: REDIRECT_URI,
    scopes: ["openid", "profile", "email"],
    pkce: true,
    disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
  },
};

export default config;
