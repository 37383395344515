import React, { Component, useEffect, useState, useRef, useCallback } from 'react';
import { DynamicForm, CustomTop, PaStepper, ButtonInput, CustomTag, NavigationMenu } from '@emerson/dynamic-ui-dev';
// import { DynamicForm, NavigationMenu, CustomTop, PaStepper } from '../../components-library';
import { Box, Container, MenuItem, Typography, Modal } from '@mui/material';
import { getEnv, getTranslateCode, lowerToUpper } from "../../utils/Helper";
import { technologyComparision, fluidPropertiesCalculation } from "../../business-logics/FlowPA/BusinessFunctions";
import { useTranslation } from 'react-i18next';
// import { CustomTag, NavigationMenu } from '../../components-library';

const bu_code1 = getEnv("REACT_APP_BU_CODE");

interface Props {
    getSchemaData: (schema_id: string) => void;
    getLeftAnchorData: (payload: any) => void;
    getRightAnchorData: (payload: any) => void;
    updateLeftAnchorData: (payload: any) => void;
    updateRightAnchorKeyData: (payload: any) => void;
    updateRightAnchorData: (payload: any) => void;
    updateLeftAnchorkeyData: (payload: any) => void;
    clearRightAnchorData: (payload: any) => void;
    getProductionListData: (payload: any) => void;
    clearRightAnchorValues: (payload: any) => void;
    schemaData: any;
    t: any;
    location: any;
};

const Home = ({ ...props }: Props) => {
    const { t, i18n } = useTranslation();

    const [step_id, setStep_id] = useState(0);
    const [result, setResult] = useState<any>({});
    const [show_result, setShow_result] = useState(false);
    const [bu_code, setBu_code] = useState(bu_code1);
    const [activeIndex, setActiveIndex] = useState(0);
    const [showClearWarnMsg, setClearWarnMsg] = useState(false);
    const [initheight, setHeight] = useState(84);

    let ChildRef = useRef<any>();

    useEffect(() => {
        const { getLeftAnchorData, location } = props;
        let params = new URLSearchParams(location.search.substring(1));
        if (params.get("bu_code")) {
            getLeftAnchorData({
                business_unit_code: params.get("bu_code"),
                p_traffic_source_code: 'NORMAL_ENTRY',
                lang: lowerToUpper(i18n.language)
            });
        } else { 
            getLeftAnchorData({
                business_unit_code: bu_code,
                p_traffic_source_code: 'NORMAL_ENTRY',
                lang: lowerToUpper(i18n.language)
            });
        }
    }, [])

    useEffect(() => {
        let divHeight: any = document.querySelector('#customTopId');
        setHeight(divHeight?.offsetHeight + 84)
    }, [props.schemaData.left_anchor])


    useEffect(() => {
        setStep_id(props.schemaData.defaultNeId);
    }, [props.schemaData.defaultNeId])


    useEffect(() => {
        const curSelectIndex = props.schemaData.left_anchor.findIndex((obj: any) => obj.ne_id === step_id)
        setActiveIndex(curSelectIndex);
    }, [step_id])

    const changeBuCode = (bu_code: any) => {
        const { getLeftAnchorData, clearRightAnchorData } = props;
        clearRightAnchorData([]);
        setResult({})
        getLeftAnchorData({
            business_unit_code: bu_code,
            p_traffic_source_code: 'NORMAL_ENTRY',
            lang: lowerToUpper(i18n.language)
        });
    }

    const updateData = (formObj: any, name: any) => {
        setResult({ ...result, ...formObj })
        colletBadges(step_id, { result, ...formObj }, name)
    }

    const onNavigationSelect = (sn_id: number, ne_id: number, name: string, bu_code: string) => {
        const { updateLeftAnchorData, schemaData } = props;
        setStep_id(ne_id);
        schemaData.left_anchor.forEach((obj: any) => {
            obj.selected = false;
            if (obj.ne_id === ne_id) {
                obj.selected = true;
                obj.enabled = true;
            }
        });
        updateLeftAnchorData(schemaData.left_anchor);
    }

    const onRAhandleChange = (e: any, formObj: any, curFormData: any, name: any, error: object) => {
        const { updateRightAnchorKeyData, updateRightAnchorData, schemaData } = props;
        const fields = curFormData.find((res: any) => res.id === step_id);
        const field = fields.fields.find((res: any) => res.name === name);
        if (fields && field && field.updateFlag && field.targetField && field.updateKey && field.updateValue) {
            if (field.updateFlag == formObj[name]) {
                updateRightAnchorKeyData({
                    ne_id: step_id,
                    name: field.targetField,
                    [field.updateKey]: field.updateValue
                })
            } else {
                const targetObject = fields.fields.find((res: any) => res.name === field.targetField);
                const targetValueInInitOptions = targetObject.initOptions.find((option: any) => option.value === formObj[field.targetField])
                if (!targetValueInInitOptions) {
                    updateRightAnchorKeyData({
                        ne_id: step_id,
                        name: field.targetField,
                        value: ""
                    })
                }
                updateRightAnchorKeyData({
                    ne_id: step_id,
                    name: field.targetField,
                    [field.updateKey]: targetObject.initOptions
                })
            }
        }

        // fpa start
        if (name == "fpa_rh_TechnologyType") {
            let func = technologyComparision(formObj[name]);
            func.then((data) => {
                updateRightAnchorKeyData({
                    ne_id: step_id,
                    name: 'fpa_rh_TechnologyComparison',
                    options: data
                })
            })

        }
        if (name == "fpa_rh_IamnotSure") {
            updateRightAnchorKeyData({
                ne_id: step_id,
                name: 'fpa_rh_LineSize',
                disabled: formObj['fpa_rh_IamnotSure'].length === 0 ? false : true
            })
        }
        if (name == "fpa_rh_ProcessVariables") {
            let func = fluidPropertiesCalculation(formObj[name]);

            const index: number = schemaData.right_anchor.findIndex(
                (x: any) => x.id === step_id
            );
            let element_index = schemaData.right_anchor[index].fields.findIndex(
                (x: any) => x.name === "fpa_rh_FluidProperties"
            );
            let options = schemaData.right_anchor[index].fields[element_index].options;

            func.then((data) => {
                data = data.data;
                for (const key in data) {
                    options.map((obj: any, index: number) => {
                        obj = obj.map((res: any, idx: number) => {
                            if (res.name === key && data[key]) {
                                options[index][idx].value = Number(data[key]).toFixed(4)
                            }
                        });
                    });
                }
                updateRightAnchorKeyData({
                    ne_id: step_id,
                    name: 'fpa_rh_FluidProperties',
                    options: options
                })
            })
        }
        if (name == "fpa_lh_CONFIGURE_Submit" || name == "fpa_lh_WORKSPACE_Submit") {
            setShow_result(true);
        }
        // fpa end
        updateRightAnchorData({
            ne_id: step_id,
            value: curFormData
        })

        updateData(formObj, name)
    }


    const getLabelFromOptions = (value: any, options: any[]) => {
        const object = options.find((option) => option.value ? option.value == value : option.id == value);
        return object && object.label ? object.label : value
    }

    const colletBadges = (step_id: any, formObj: any, name?: any) => {
        const { updateLeftAnchorkeyData, schemaData } = props;

        let badges: any = [];
        const index: number = schemaData.right_anchor.findIndex(
            (x: any) => x.id === step_id
        );
        const hiddenCloseArr = [
            "fpa_rh_FluidType",
            "fpa_rh_FluidSource",
            "fpa_rh_Mounting",
            "fpa_rh_WirelessOptions",
            "fpa_rh_InterfaceOptions",
            "fpa_rh_FlowAccuracy",
            "fpa_rh_DensityAccuracy"
        ]
        const disableValueArr = [
            "Historian"
        ]
        schemaData.right_anchor[index].fields.forEach((field: any) => {
            if (field.value && Object.prototype.toString.call(field.value) == '[object Array]') {
                field.value.forEach((fiel: any) => {
                    badges.push({
                        label: field.options ? getLabelFromOptions(fiel, field.options) : fiel,
                        id: fiel,
                        name: field.name,
                        hiddenClose: hiddenCloseArr.includes(field.name),
                        disableClose: disableValueArr.includes(fiel)
                    })
                    if (formObj['fpa_rh_IamnotSure'] && formObj['fpa_rh_IamnotSure'].length && name === "fpa_rh_IamnotSure") {
                        badges = badges.filter((item: any) => item.name !== 'fpa_rh_LineSize')
                    }
                })
            } else if (field.value && (Object.prototype.toString.call(field.value) == '[object String]' || Object.prototype.toString.call(field.value) == '[object Number]')) {
                badges.push({
                    label: field.options ? getLabelFromOptions(field.value, field.options) : field.value,
                    id: field.value,
                    name: field.name,
                    hiddenClose: hiddenCloseArr.includes(field.name)
                })
            }
        })
        updateLeftAnchorkeyData({
            ne_id: step_id,
            key: "badges",
            value: badges
        })
    }

    const onRAhandleKeyPress = (e: any) => {

    }

    const onStepchange = async (item: any, index: any) => {
        if (index > activeIndex && ChildRef && ChildRef.current && ChildRef.current.validationFormAllLogic) {
            let forward = await ChildRef.current.validationFormAllLogic();
            if (!forward) {
                return ""
            }
        }
        // setActiveIndex(index);
        const { getRightAnchorData, getProductionListData, schemaData } = props;
        const { sn_id, ne_id, name, bu_code } = item;
        if (bu_code == 'LTA' && index == schemaData.left_anchor.length - 1) {
            getProductionListData({ ...result, sn_id, ne_id, name, bu_code, lang: lowerToUpper(i18n.language), notrenderd: schemaData.right_anchor.filter((obj: any) => obj.id === ne_id).length == 0 })
        } else {
            if (schemaData.right_anchor.filter((obj: any) => obj.id === ne_id).length === 0) {
                
                let p_request_json:any = {};
                for (let i = 0; i < schemaData.right_anchor.length; i++) {
                    if (schemaData.right_anchor[i].id === ne_id) {
                        break;
                    }
                    schemaData.right_anchor[i].fields.forEach((field: any) => {
                        p_request_json[field.name] = field.value;
                    });
                }
                getRightAnchorData({
                    business_unit_code: bu_code,
                    p_session_navigation_id: sn_id,
                    p_ne_id: ne_id,
                    p_request_json: p_request_json,
                    lang: lowerToUpper(i18n.language),
                });
            }
        }
        onNavigationSelect(sn_id, ne_id, name, bu_code);
    }

    const deleteHander = (tagObject: any, item: any) => {
        const { schemaData, updateRightAnchorKeyData, updateLeftAnchorkeyData, updateLeftAnchorData } = props;
        const { label, id, name } = tagObject;
        if (result[name]) {
            let leftValue = '';
            if (Object.prototype.toString.call(result[name]) == '[object Array]') {
                leftValue = result[name].filter((fie: any) => fie !== id);
            }
            setResult({ ...result, [name]: leftValue })
            updateRightAnchorKeyData(
                {
                    ne_id: item.ne_id,
                    name: name,
                    value: leftValue
                }
            )
        }
        const updateItem = schemaData.left_anchor.find((ite: any) => ite.ne_id == item.ne_id)
        const filterIndex = updateItem.badges.findIndex((ite: any) => (ite.id === tagObject.id && ite.name === tagObject.name));
        const badges = updateItem.badges.filter((item: any, index: number) => index !== filterIndex)
        updateLeftAnchorkeyData(
            {
                ne_id: item.ne_id,
                key: "badges",
                value: badges
            }
        )
        if (name == "fpa_rh_TechnologyType") {
            let func = technologyComparision(result[name]);
            func.then((data) => {
                updateRightAnchorKeyData({
                    ne_id: item.ne_id,
                    name: 'fpa_rh_TechnologyComparison',
                    options: data
                })
            })
        }
        if (name === 'fpa_rh_IamnotSure') {
            if (result["fpa_rh_LineSize"]) {
                updateLeftAnchorkeyData(
                    {
                        ne_id: item.ne_id,
                        key: "badges",
                        value: [{
                            label: result["fpa_rh_LineSize"],
                            id: result["fpa_rh_LineSize"],
                            name: "fpa_rh_LineSize",
                        }]
                    }
                )
            }
            updateRightAnchorKeyData({
                ne_id: item.ne_id,
                name: 'fpa_rh_LineSize',
                disabled: false
            })
        }

        const fields = schemaData.right_anchor.find((res: any) => res.id === item.ne_id);
        const field = fields.fields.find((res: any) => res.name === name);
        if (fields && field && field.updateFlag && field.targetField && field.updateKey && field.updateValue) {
            if (field.updateFlag == id) {
                const targetObject = fields.fields.find((res: any) => res.name === field.targetField);
                const targetValueInInitOptions = targetObject.initOptions.find((option: any) => option.value === targetObject.value)
                if (!targetValueInInitOptions) {
                    updateRightAnchorKeyData({
                        ne_id: item.ne_id,
                        name: field.targetField,
                        value: ""
                    })
                    updateLeftAnchorkeyData({
                        ne_id: item.ne_id,
                        key: "badges",
                        value: badges.filter((ite: any) => ite.id !== targetObject.value)
                    })
                }
                updateRightAnchorKeyData({
                    ne_id: item.ne_id,
                    name: field.targetField,
                    [field.updateKey]: targetObject.initOptions
                })
            }
        }

        const curSelectIndex = schemaData.left_anchor.findIndex((obj: any) => obj.ne_id === item.ne_id)
        schemaData.left_anchor.forEach((obj: any, index: number) => {
            if (index > curSelectIndex) {
                obj.badges = [];
                obj.enabled = false
            }
        });

        updateLeftAnchorData(schemaData.left_anchor);
        onNavigationSelect(item.sn_id, item.ne_id, item.name, item.bu_code);
    }

    const handleClose = () => { setShow_result(false); };

    const handleClear = () => {
        setClearWarnMsg(true);
    }

    useEffect(() => {
        props.schemaData.left_anchor.forEach((element: any) => {
            if (element.badges.length == 0) {
                setClearWarnMsg(false)
            }
        });
    }, [props.schemaData.left_anchor])

    const handleCheckbox = () => {
            // If user selects 'YES', perform additional actions (e.g., clearing data)
            const { clearRightAnchorValues, schemaData } = props;
            clearRightAnchorValues({});
            onNavigationSelect(0, schemaData.defaultNeId, '', bu_code);
    };

    const disableClear = () => {
        let isDisable = true;
        props.schemaData.left_anchor.forEach((element: any) => {
            if (element.badges.length > 0) {
                isDisable = false;
            }
        });
        return isDisable;
    };
 
    const getValueShow = (test: any) => {
        setClearWarnMsg(test);
    }

    return (
        <Container maxWidth="xl" sx={{
            '&.MuiContainer-root': {
                paddingTop: '0'
            }
        }}>
            <div id="customTopId" className="ddl-grid ddl-grid--cols-12" style={{ position: 'sticky', top: "84px", backgroundColor: 'white', zIndex: 1 }}>
                <div className='ddl-grid--col-span-12'>
                    {props.schemaData && props.schemaData.top_anchor &&
                        <CustomTop data={props.schemaData.top_anchor} />
                    }
                </div>
                <div className='ddl-grid--col-span-3'>
                </div>
                <div className='ddl-grid--col-span-9'>
                    {props.schemaData.left_anchor.length !== 0 &&
                        <PaStepper activeIndex={activeIndex} onChange={onStepchange} data={props.schemaData.left_anchor} />
                    }
                </div>
            </div>
            <div className="ddl-grid ddl-grid--cols-12">
                <div className='ddl-grid--col-span-3 custom-scrollbar' style={{ height: `calc(100vh - ${initheight}px)`, overflow: 'auto' }}>
                    <div> {
                        props.schemaData.left_anchor.length !== 0 &&
                        <NavigationMenu
                            data={props.schemaData.left_anchor}
                            onDelete={deleteHander}
                            onSelect={onNavigationSelect}
                        />
                    }
                    </div>
                    {/* Start Clear Button func */}
                    {<div style={{ marginTop: 10 }}>
                        <CustomTag showWarnMsg={showClearWarnMsg} sendMsgVal={(e: any) => getValueShow(e)} onChange={handleCheckbox} />
                    </div>}
                    {props.schemaData.left_anchor.length > 0 && 
                    <div className={"clear_btn"}>
                        <a className={`${disableClear() ? 'disable-btn': 'clear-all'}`} onClick={handleClear}>{t("CLEAR_ALL")} </a>
                        <div style={{display:'none', float:'right'}}><ButtonInput disabled={disableClear()} btnType="secondary" onClick={handleClear} label="Clear" /></div>
                    </div>}
                    {/* End Clear Button func */}
                </div>
                <div className='ddl-grid--col-span-9' style={{ height: `calc(100vh - ${initheight}px)`, overflow: 'auto', paddingLeft: '4px' }}>
                    <div>
                        {props.schemaData.right_anchor.filter((obj: any) => obj.id === step_id).length !== 0 &&
                            <DynamicForm  ref={ChildRef} values= {result} schema={props.schemaData.right_anchor.filter((obj: any) => obj.id === step_id) as any} handleChange={onRAhandleChange} handleKeyPress={onRAhandleKeyPress}  handleSubmit={() => { }} formKey={step_id}/>

                        }
                    </div>
                </div>
            </div>

            <Modal
                open={show_result}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: "50%",
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        <span>Selections</span> <span onClick={handleClose} style={{ float: "right", color: "gray", cursor: "pointer" }}>X</span>
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <div style={{ height: "400px", overflowY: "auto", width: "100%" }}><table className="table table-hover">
                            <tbody>
                                <tr>
                                    <th>Key</th>
                                    <th>Value</th>
                                </tr>
                                {result && Object.keys(result).map((key: any, value: any) => (
                                    <tr className="table-row" key={key}>
                                        <td key={`tablekey-${key}`}>{key}</td>
                                        <td key={`tablevalue-${key}`}>{result[key] ? result[key].toString() : ''}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table></div>
                    </Typography>
                </Box>
            </Modal>
        </Container>
    );
}


export default Home;
