import React, { Fragment } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import img from '../../assets/images/loading.gif';

interface Props {
  loading: boolean
}

export default function Loader({ loading }: Props) {

  return (
    <Fragment>
      {
        loading && <div>
          <div>
            <LinearProgress />
          </div>
          <div className="loaderBackground">
            <img src={img} alt="loading" className="loaderStyle" />
          </div>
        </div>
      }
    </Fragment>
  );
}